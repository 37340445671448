import { API_KEY, API_HOST, PARENT_NAME, SELECTOR } from "./constants";
import { signinRedirect } from "./utils/checkSignIn";

const BUILD_TIME = process.env.BUILD_TIME;

declare global {
  var __webpack_public_path__: string;

  interface Window {
    OutsideLogin: any;
  }
}

class OutsideLoginClass {
  config: any;
  buttonCalls: any;

  constructor() {
    const script = document.currentScript as any;
    const scriptOrigin = new URL(script.src).origin;
    const apiKey =
      document.currentScript?.getAttribute("data-api-key") || API_KEY;
    const apiHost =
      document.currentScript?.getAttribute("data-api-host") || API_HOST;
    const signInRole = document.currentScript?.getAttribute("data-signin");
    const nextUrl = document.currentScript?.getAttribute("data-next");
    const csrf = document.currentScript?.getAttribute("data-csrf");
    const signInProvider =
      document.currentScript?.getAttribute("data-provider");
    const useWindowAnalyticsEvents =
      document.currentScript?.getAttribute("use-window-analytics-events") ===
      "true";
    const parentName = document.currentScript?.getAttribute("data-parent-name") || PARENT_NAME || window.location.hostname;
    let logLevel = document.currentScript?.getAttribute("data-log");
    if (!logLevel && scriptOrigin.includes("-preview.")) logLevel = "verbose";

    // Dynamic path for chunk loading.
    __webpack_public_path__ = scriptOrigin + "/";

    this.config = {
      apiKey,
      apiHost,
      parentName,
      selector: SELECTOR,
      caller: this,
      scriptOrigin,
      signInRole,
      signInProvider,
      nextUrl,
      csrf,
      useWindowAnalyticsEvents,
      logLevel,
    } as any;
    this.buttonCalls = {};
  }

  init(config: object) {
    this.config = { ...this.config, ...config };
    const { selector } = this.config;
    let target = document.querySelector(selector);
    import("./app").then((app) => {
      if (!!target) {
        app.mount(target, this.config);
        console.log(
          "started OutsideLogin app in",
          selector,
          "build",
          BUILD_TIME
        );
      } else {
        console.error("could not find element", selector);
      }
    });
  }

  start() {
    const { apiKey, apiHost, signInRole, signInProvider } = this.config;
    // Noop if signinRole is not set
    signinRedirect.checkSignin({ apiKey, apiHost, signInRole, signInProvider });
  }

  setButtonCalls(calls: object) {
    this.buttonCalls = { ...this.buttonCalls, ...calls };
  }

  // These methods are overridden after mount
  signout() {}
}

if (!window.OutsideLogin) {
  const OutsideLogin = new OutsideLoginClass();
  window.OutsideLogin = OutsideLogin;
  OutsideLogin.start();
  console.log("OutsideLogin ready to be started");
}
