import axios from "axios";
import jwt_decode from "jwt-decode";
import {
  API_KEY,
  RIVT_MEMBERSHIP_COOKIE_NAME,
  RIVT_TOKEN_COOKIE_NAME,
  APOLLO_EMAIL_LOGIN,
  APOLLO_LOGIN_URL,
  APOLLO_LOGOUT_URL,
  APOLLO_REFRESH_URL,
  FUSION_LOGIN_URL,
  FUSION_LOGOUT_URL,
  RIVT_LOGIN_URL,
  APOLLO_ACCESS_TOKEN_COOKIE_NAME,
  APOLLO_REFRESH_TOKEN_COOKIE_NAME,
  APOLLO_VALIDATE_URL,
} from "../constants";
import { setCookie, removeCookie, getCookie } from "../utils/cookies";
import { signOut } from "./auth";

const tokenParam = "token";
const tokenNames = ["a_acc_token", "a_ref_token", "r_token"];

const defaultApiKey = () => {
  // For rivt signin demo.
  // When running as a signin host, the api key must match the domain where the bundle runs,
  // not the domain where it was built.
  const hostname = window.location.hostname;
  if (hostname.includes("prod-api.rivt.com")) return "Nc8Y8wIc.pNo20zKirnFbJMyZX2QF4JMN0sRDkFsX";
  if (hostname.includes("staging-api.rivt.com")) return "cYFhvJiB.iH7dYDY2KECozZ1idgNaRW3gvZ0OkMhn";
  if (hostname.includes("dev-admin.rivt.com")) return "ORfZIvLL.xuIB27UZrUc4dGRlhBZmX92H14ZVSj4X";
  if (hostname.includes("rivt.localhost")) return "ORfZIvLL.xuIB27UZrUc4dGRlhBZmX92H14ZVSj4X";
  return API_KEY;
};

export const signinRedirect = {
  params: {
    apiKey: null,
    apiHost: null,
    authToken: null,
    signInRole: null,
    signInProvider: null,
  } as any,

  checkSignin: function (params: any) {
    this.params = { ...this.params, ...params };
    const { signInRole, signInProvider } = this.params;
    let authToken;

    if (signInRole === "host") {
      this.hostShowSignIn();
    } else if (signInRole === "oidc") {
      this.oidcShowSignIn(null);
    } else if (signInRole) {
      switch (signInProvider) {
        case "fusion":
          // "null" is a valid value, indicates "not signed in".
          authToken = getCookie(RIVT_TOKEN_COOKIE_NAME) || this.getUrlToken();
          // TODO: create a redirect to check status without prompting to sign in, for signInRole === "check".
          if (authToken === undefined && signInRole === "signin")
            this.redirectToFusion(params);
          break;
        case "rivt":
          // "null" is a valid value, indicates "not signed in".
          authToken = getCookie(RIVT_TOKEN_COOKIE_NAME) || this.getUrlToken();
          if (authToken === undefined) this.redirectToRivt(params);
          break;
        case "apollo":
          this.checkApolloAuth(params);
          break;
        default:
          console.log(`Sign in provider, "${signInProvider}", not supported`);
          break;
      }
    }
  },

  getUrlToken: function () {
    // Client-side parameter parsing.
    const urlHashData = getHashParams(window.location.hash) || {};
    const urlParams = new URLSearchParams(window.location.href);

    const refreshToken = urlParams.get("a_ref_token");
    const accessToken = urlParams.get("a_acc_token");

    // Supergraph needs both access and refresh so make them an object on authToken
    const authToken =
      accessToken && refreshToken
        ? { accessToken: accessToken, refreshToken: refreshToken }
        : urlHashData[tokenParam];

    if (authToken !== undefined) this.saveToken(authToken);
    // We don't want the token to always show up in the url.
    // First check to see if the urlHashData is there. If it is, do the first thing otherwise, it's just regular query params so do the second
    if (Object.keys(urlHashData).length !== 0) {
      this.hideToken(urlHashData);
    } else if (authToken) this.deleteSearchParams();

    return authToken;
  },

  hideToken: function (urlHashData: any) {
    delete urlHashData[tokenParam];
    const qs = urlQuery(urlHashData);
    window.location.hash = window.location.hash.split("?")[0] + qs;
  },

  deleteSearchParams: (): void => {
    let resetUrl = new URL(window.location.href);
    const tokeep = Array.from(resetUrl.searchParams).filter(([k, v]) => !tokenNames.includes(k));
    const search = new URLSearchParams(tokeep);
    resetUrl.search = search.toString();
    window.history.replaceState(null, '', resetUrl.toString());
  },

  saveToken: function (authToken: any) {
    const { apiKey, apiHost, signInProvider } = this.params;
    this.params = { ...this.params, authToken };
    removeCookie(RIVT_TOKEN_COOKIE_NAME);

    if (authToken) {
      setCookie(RIVT_TOKEN_COOKIE_NAME, authToken);
      // For browsers that block third-party js from setting cookies.
      window.OutsideLogin.config.authToken = authToken;
    } else {
      signOut({ apiKey, apiHost }).finally();
    }
  },

  // Save tokens from apollo
  saveAccessAndRefreshTokens: function (accessToken: any, refreshToken: any) {
    if (accessToken && refreshToken) {
      removeCookie(APOLLO_REFRESH_TOKEN_COOKIE_NAME);
      removeCookie(APOLLO_ACCESS_TOKEN_COOKIE_NAME);
      setCookie(APOLLO_REFRESH_TOKEN_COOKIE_NAME, refreshToken);
      setCookie(APOLLO_ACCESS_TOKEN_COOKIE_NAME, accessToken);
    }
  },

  saveAccessRefreshAndRivtTokensOnWindow: function (
    accessApollo: any,
    refreshApollo: any,
    rivtToken?: any
  ) {
    // Check to make sure the window.config has the access and refresh tokens
    if (!window.OutsideLogin.config.apollo_access_token && accessApollo) {
      window.OutsideLogin.config.apollo_access_token = accessApollo;
    }

    if (!window.OutsideLogin.config.apollo_refresh_token && refreshApollo) {
      window.OutsideLogin.config.apollo_refresh_token = refreshApollo;
    }

    if (!window.OutsideLogin.config.authToken && rivtToken) {
      window.OutsideLogin.config.authToken = rivtToken;
    }
  },

  checkApolloAuth: function (params: any) {
    const searchParams = new URL(window.location.href).searchParams;
    if (!searchParams.has("a_acc_token")) this.redirectToApollo(params);

    const refreshToken = searchParams.get("a_ref_token");
    const accessToken = searchParams.get("a_acc_token");
    const rivtToken = searchParams.get("r_token");
    this.deleteSearchParams();

    if (accessToken && refreshToken && rivtToken) {
      this.saveAccessRefreshAndRivtTokensOnWindow(accessToken, refreshToken, rivtToken);
      this.saveAccessAndRefreshTokens(accessToken, refreshToken);
      this.saveToken(rivtToken);
    } else this.cleanAll();
  },

  cleanAll: function () {
    window.OutsideLogin.config.apollo_refresh_token = null;
    window.OutsideLogin.config.apollo_access_token = null;
    window.OutsideLogin.config.authToken = null;
    removeCookie(APOLLO_REFRESH_TOKEN_COOKIE_NAME);
    removeCookie(APOLLO_ACCESS_TOKEN_COOKIE_NAME);
    removeCookie(RIVT_TOKEN_COOKIE_NAME);
    removeCookie(RIVT_MEMBERSHIP_COOKIE_NAME);  // indicator of paid membership
  },

  isApolloAuthenticated: function (params: any) {
    const accessToken = new URL(window.location.href).searchParams.get(
      "a_acc_token"
    );
    const refreshToken = new URL(window.location.href).searchParams.get(
      "a_ref_token"
    );
    const rivtToken = new URL(window.location.href).searchParams.get("r_token");
    const rivtCookie = getCookie(RIVT_TOKEN_COOKIE_NAME);
    let accessCookie = getCookie(APOLLO_ACCESS_TOKEN_COOKIE_NAME);
    let refreshCookie = getCookie(APOLLO_REFRESH_TOKEN_COOKIE_NAME);

    if (accessToken) {
      if (accessToken !== accessCookie) {
        this.saveAccessAndRefreshTokens(accessToken, refreshToken);
        this.deleteSearchParams();
      }
    }

    // Check if there are cookies
    if (!accessCookie || !refreshCookie || !rivtCookie) {
      // If no cookies, check the url
      if (!accessToken || !refreshToken || !rivtToken) {
        // If there's nothing in the URL either, then go get them from apollo
        this.redirectToApollo(params);
      } else {
        // If there is something in the URL, go save it as a cookie
        this.saveAccessAndRefreshTokens(accessToken, refreshToken);
        this.saveToken(rivtToken);
        // Also see if they're on the window and if not, go save there
        if (
          !window.OutsideLogin.config.apollo_access_token ||
          !window.OutsideLogin.config.apollo_refresh_token
        )
          this.saveAccessRefreshAndRivtTokensOnWindow(
            accessToken,
            refreshToken
          );
        // And then delete the tokens from the url
        this.deleteSearchParams();
      }
    } else {
      if (accessCookie) {
        const decodedAccessToken = jwt_decode(accessCookie) as any;
        const expiryDate = new Date(decodedAccessToken.exp * 1000);

        if (expiryDate < new Date()) {
          this.validateToken(accessCookie, refreshCookie);
        }
      }
      this.saveAccessRefreshAndRivtTokensOnWindow(
        accessCookie,
        refreshCookie,
        rivtCookie
      );
      this.deleteSearchParams();
    }
  },

  // If running as a signin host. Mount the header in the target dom element, to display the signin page.
  hostShowSignIn: function () {
    const { signInRole } = this.params;
    const urlParams = new URLSearchParams(window.location.search);
    const nextUrl = urlParams.get("next");
    const signInMethod = urlParams.get("signin");
    removeCookie(RIVT_TOKEN_COOKIE_NAME); // authenticate by session only

    window.OutsideLogin.init({
      apiKey: defaultApiKey(),
      apiHost: window.location.origin,
      signInRole,
      signInMethod,
      nextUrl,
    });
  },

  oidcShowSignIn: function (params: any) {
    params = { ...this.params, ...params };
    const { signInRole } = params;
    removeCookie(RIVT_TOKEN_COOKIE_NAME);

    window.OutsideLogin.init({
      signInRole,
    });
  },

  // For use in signin provider role
  returnFromCheck: function (nextUrl: string, authToken: string) {
    const urlParts = nextUrl.split("#");
    const hashStr = urlParts.length > 1 ? urlParts.pop() || "" : "";
    const hashData = getHashParams(hashStr || "") || {};
    hashData.token = authToken || "";
    const url = urlParts[0] + "#" + hashStr.split("?")[0] + urlQuery(hashData);
    window.location.href = url;
  },

  redirectToApollo: function (params: any) {
    const { signInRole } = params;
    const url = new URL(APOLLO_LOGIN_URL as string);
    const nextUrl = new URL(window.location.href);
    const tokeep = Array.from(nextUrl.searchParams);
    const search = new URLSearchParams(tokeep);
    nextUrl.search = search.toString();
    url.searchParams.append('signin', signInRole);
    url.searchParams.append('next', nextUrl.toString());
    window.location.href = url.toString();
  },

  updateApolloTokens: async function () {
    const refreshToken = window.OutsideLogin.config.apollo_refresh_token;
    const accessToken = window.OutsideLogin.config.apollo_access_token;

    if (refreshToken) {
      function delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
      delay(1000);
      const refreshUrl = new URL(APOLLO_REFRESH_URL as string);
      refreshUrl.searchParams.append('grant_type', 'refresh_token');
      refreshUrl.searchParams.append('refresh_token', refreshToken);
      refreshUrl.searchParams.append('access_token', accessToken);

      try {
        const res = await axios.get(refreshUrl.toString());
        if (res.status === 200) {
          const accessToken = res.data.access_token;
          const refreshToken = res.data.refresh_token;
          this.saveAccessAndRefreshTokens(accessToken, refreshToken);
          return [accessToken, refreshToken];
        }
      } catch { }
    }
    this.cleanAll();
    return [null, null];
  },

  doTokenRefresh: async function (params: any) {
    const { signInRole } = params;
    if (signInRole === "apollo") await this.updateApolloTokens();
  },

  redirectToRivt: function (params: any) {
    const { apiHost, signInRole } = params;
    const urlParams = { signin: signInRole, next: location.href };
    const url = apiHost + RIVT_LOGIN_URL + urlQuery(urlParams);
    window.location.href = url;
  },

  redirectToFusion: function (params: any) {
    const { apiHost } = params;
    const urlParts = window.location.href.split("#");
    const hashStr = urlParts.length > 1 ? urlParts.pop() || "" : "";
    const back =
      urlParts[0] + "#" + hashStr.split("?")[0] + "?token={rivt_token}";
    const urlParams = { redirect: back };
    let url = apiHost + FUSION_LOGIN_URL + urlQuery(urlParams);
    window.location.href = url;
  },

  signOut: function (params: any) {
    const { apiKey, apiHost, signInProvider } = params;
    switch (signInProvider) {
      case "apollo":
        // Redirect to apollo gateway for signout.
        this.signOutApollo();
        break;
      case "fusion":
      case "rivt":
        // Redirect to rivt for signout.
        this.signOutFusion(params);
        break;
      default:
        return signOut({ apiKey, apiHost }).finally();
    }
  },

  signOutFusion: function (params: any) {
    const { apiHost, user } = params;
    const urlParams = { next: location.href } as any;
    if (user?.uuid) urlParams.uuid = user.uuid;
    const url = apiHost + FUSION_LOGOUT_URL + urlQuery(urlParams);
    removeCookie(RIVT_TOKEN_COOKIE_NAME);
    removeCookie(APOLLO_ACCESS_TOKEN_COOKIE_NAME);
    removeCookie(APOLLO_REFRESH_TOKEN_COOKIE_NAME);
    window.location.href = url;
  },

  validateToken: function (accessToken: any, refreshToken: any) {
    const url = new URL(`${APOLLO_VALIDATE_URL}`);

    url.searchParams.append("a_acc_token", `${accessToken}`);
    url.searchParams.append("a_ref_token", `${refreshToken}`);
    url.searchParams.append("next", location.href);

    window.location.href = `${url}`;
  },

  signOutApollo: async function () {
    const [accessToken, refreshToken] = await this.updateApolloTokens();
    if (accessToken && refreshToken) {
      try {
        const logoutUrl = new URL(APOLLO_LOGOUT_URL as string);
        logoutUrl.searchParams.append('refresh_token', refreshToken);
        const headers = { Authorization: "Bearer " + accessToken };
        const resOut = await axios.get(logoutUrl.toString(), { headers });
        if (resOut.status === 200) {
          removeCookie(RIVT_TOKEN_COOKIE_NAME);
          removeCookie(APOLLO_ACCESS_TOKEN_COOKIE_NAME);
          removeCookie(APOLLO_REFRESH_TOKEN_COOKIE_NAME);
        }
      } catch (err) {
        console.log(err);
      }
    }
  },

  loginApolloEmail: async function (params: any) {
    const { email, password } = params;
    try {
      const res = await axios.post(APOLLO_EMAIL_LOGIN as string, { email, password });
      const { accessToken, refreshToken } = res.data;
      window.OutsideLogin.config.apollo_refresh_token = refreshToken;
      window.OutsideLogin.config.apollo_access_token = accessToken;
      this.saveAccessAndRefreshTokens(accessToken, refreshToken);
    } catch {
      this.cleanAll();
    }
  },
};

export const getHashParams = (hashString: string) => {
  let queryIndex = hashString?.indexOf("?");
  if (queryIndex >= 0) {
    const hashData = Object.fromEntries(
      new URLSearchParams(hashString.slice(queryIndex + 1))
    ) as any;
    for (const [k, v] of Object.entries(hashData)) hashData[k] = v || null;
    return hashData;
  }
};

export const getAllParams = (location: any) => {
  // Discount from url search query.
  const urlParams = new URLSearchParams(location.search);
  // Discount from url hash query, if any.
  const hashParams = new URLSearchParams(location.hash?.split('?')[1]);
  // Combine search and hash query parameters.
  const allParams = Object.fromEntries(
    [
      ...urlParams.entries(),
      ...hashParams.entries(),
    ].filter(([k, v]) => v !== undefined)
  );
  return allParams;
};

export const urlQuery = (urlData: any) => {
  let qs = (urlData && new URLSearchParams(urlData).toString()) || "";
  return qs && "?" + qs;
};
