import Cookies from "js-cookie";

const defaultOptions = {
  expires: 365,
  sameSite: "None",
  secure: true,
} as any;

export const getCookie = (cookieName: string) => {
  return Cookies.get(cookieName);
};

export const setCookie = (cookieName: string, cookieValue: any) => {
  Cookies.remove(cookieName, defaultOptions);
  Cookies.set(cookieName, cookieValue, defaultOptions);
};

export const removeCookie = (cookieName: string) => {
  Cookies.remove(cookieName, defaultOptions);
};
